<template>
  <div
    class="w-full py-2 rounded-xl border-blue-dark border-2 flex flex-col sm:flex-row justify-around items-center"
  >
    <div class="w-full py-2">
      <p>
        <b class="text-blue-dark">{{ detail ? "Radicado" : "Señor" }}</b>
      </p>
      <p v-if="radicado">
        <b>{{ radicado }}</b>
      </p>
      <p v-if="name">{{ name }}</p>
    </div>
    <span class="block w-3/4 h-px sm:w-1 sm:h-16 bg-blue-dark"></span>
    <div class="w-full py-2">
      <p>
        <b class="text-blue-dark">{{ detail ? "Estado" : "Email" }}</b>
      </p>
      <p v-if="email">{{ email }}</p>
      <ComplaintStatusLabel v-if="status" :status="status" danger="true" />
    </div>
    <span
      v-if="principal"
      class="block w-3/4 h-px sm:w-1 sm:h-16 bg-blue-dark"
    ></span>
    <div v-if="principal" class="w-full py-2">
      <p><b class="text-blue-dark">Estado de duplicidad</b></p>
      <ComplaintStatusLabel
        v-if="principal"
        :status="principal"
        danger="true"
      />
    </div>
  </div>
</template>

<script>
import ComplaintStatusLabel from "@/components/Dashboard/Complaints/ComplaintStatusLabel";
export default {
  props: ["name", "radicado", "detail", "status", "email", "principal"],
  components: {
    ComplaintStatusLabel,
  },
};
</script>